import TextField from '@material-ui/core/TextField'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#4da2d9',
    },
    '& .MuiOutlinedInput-root': {
      marginBottom: '20px',
      '& fieldset': {
        borderColor: '#E8E8E8',
      },
      '&:hover fieldset': {
        borderColor: '#4da2d9',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4da2d9',
      },
    },
  },
})(TextField)
export default function BasicTextFields({ label, onChange }) {
  return <CustomTextField label={label} onChange={onChange} variant="outlined" />
}
