import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    '&:hover fieldset': {
      borderColor: '#4da2d9',
    },
    '& label.Mui-focused': {
      color: '#4da2d9',
    },
  },
  select: {
    '& fieldset': {
      borderColor: '#E8E8E8',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4da2d9',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4da2d9',
    },
  },
}))

export default function SimpleSelect({ onChange, Language, LANGUAGES }) {
  const classes = useStyles()
  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">
          Выберите язык
        </InputLabel>
        <Select
          className={classes.select}
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={Language}
          onChange={onChange}
          label="Выберите язык"
        >
          {LANGUAGES.map((el, idx) => {
            return <MenuItem value={el.value} key={idx}>{el.name}</MenuItem>
          })}
        </Select>
      </FormControl>
    </div>
  )
}
